import { axiosInstance } from "../../axios";

const GETAllCommentsByCustomerId = "api/Comment/GetByCustomerKey/";
const POSTComment = "api/Comment/Create";







const createComment = async function (Comment) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTComment, Comment);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getCommentByCustomerId = async function (id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETAllCommentsByCustomerId +id);
        return parseList(response);
    } catch (error) {
        return null;
    }
};




const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const commentData = {
    createComment,
    getCommentByCustomerId
}

