<template>
<div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">

        <form action="" @submit.prevent="handleSubmit(saveComment)">
            <div class="vx-col md-2">
                <ValidationProvider name="Comment" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" placeholder="Enter your comment" name="Comment" v-model="commentModel.Comment" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
            <vs-divider />
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-button class="mr-3 mb-2" @click.prevent="saveComment">Add Comment</vs-button>
                </div>
            </div>
        </form>
        <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
            <div class="vx-col w-full p-base">
                <ul class="vx-timeline comment-timeline">
                    <li v-for="(item, index ) in comments" :key="index">
                        <div class="timeline-icon" :class="`bg-warning`">
                            <feather-icon icon="MailIcon" svgClasses="w-5 h-5" />
                        </div>
                        <div class="timeline-info">
                            <p class="font-semibold">{{ item.commentValue }}</p>
                            <span class="activity-desc">{{ item.createdBy }}</span>
                        </div>
                        <small class="text-grey activity-e-time">{{ item.createdDate | formatLongDate  }}</small>
                    </li>
                </ul>
            </div>
        </component>
    </ValidationObserver>

</div>
</template>

<script>
import {
    customMessages
} from "../../filters/validationmessage";
import {
    commentData
} from '../../store/api/comment'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    data() {
        return {
            comments: [],
            commentModel: {
                CustomerId: ""
            },
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .60
            },
            customMessages,
        }
    },
    computed: {
        scrollbarTag() {
            return this.$store.getters.scrollbarTag
        }
    },
    components: {
        VuePerfectScrollbar
    },
    props: {
        customerId: {
            type: String,
            required: true
        }
    },
    created() {
        this.loadComments();
    },
    methods: {
        async loadComments() {
            this.comments = await commentData.getCommentByCustomerId(this.customerId);
        },
        async saveComment() {
            this.commentModel.CustomerId = this.customerId;
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await commentData.createComment(this.commentModel);

            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center",
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Comment Added",
                color: "success",
                position: "top-center",
            });
            this.loadComments();

        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";

#comments {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);

        @media screen and (max-width: 1200px) {
            min-width: 100vw;
            margin: 0px;
            max-height: 100vh;
        }

    }
}

.scroll-area--nofications-dropdown {
    max-height: 100rem;
}
</style>
